import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from 'axios'; // Ensure axios is imported
import './login.css';

// Function to fetch DMD data
const fetchDmddata = async (searchTerm) => {
  try {
    const response = await axios.get(
      'https://heuristic-cray.194-76-27-167.plesk.page/api/dmddata',
      { params: { search: searchTerm } }
    );
    return response.data; // Array of matched ampp_nm values
  } catch (error) {
    console.error('Error fetching dmddata:', error);
    return [];
  }
};

const Login = () => {
  const { setIsLoggedIn, setOrganisationId, setUserRole } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]); // Dropdown options
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  // Fetch options based on the search query
  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const fetchOptions = async () => {
        setLoading(true);
        try {
          const data = await fetchDmddata(searchQuery); // Use fetchDmddata function
          setOptions(data); // Update dropdown options
        } catch (err) {
          console.error('Error fetching options:', err);
        }
        setLoading(false);
      };
      fetchOptions();
    } else {
      setOptions([]); // Reset options if search query is empty
    }
  }, [searchQuery]);

  const handleLogin = (e) => {
    e.preventDefault();

    // Dummy authentication logic
    let orgId;
    let userRole;
    if (username === 'Lyneham' && password === 'Pharmace2023') {
      orgId = '66c285a67a5d05eff58e0109';
      userRole = 'Admin';
    } else if (username === 'GL-manager' && password === 'GLM2023') {
      orgId = '66f2a50aeaaa22da74c9dd80';
      userRole = 'Manager';
    } else if (username === 'GL-admin' && password === 'GLA2023') {
      orgId = '66f2a50aeaaa22da74c9dd80';
      userRole = 'Admin';
    } else if (username === 'Southtown' && password === 'Pharmace2023') {
      orgId = '66f84ac4fd5c9470d1ae71bf';
      userRole = 'Admin';
    }

    if (orgId) {
      setIsLoggedIn(true); // Update context state
      setOrganisationId(orgId);
      setUserRole(userRole);
      navigate('/homepage'); // Navigate to homepage
    } else {
      alert('Invalid username or password'); // Handle invalid login
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <div style={{ marginBottom: '20px', paddingTop: '30px' }}>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: '20px', paddingTop: '5px' }}>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div style={{ marginBottom: '20px', paddingTop: '5px' }}>
          <label>Search & Select:</label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for an item"
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <select
            value={dropdownValue}
            onChange={(e) => setDropdownValue(e.target.value)}
            style={{ width: '100%' }}
          >
            <option value="" disabled>
              Select an item or type...
            </option>
            {loading ? (
              <option disabled>Loading...</option>
            ) : options.length > 0 ? (
              options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))
            ) : (
              <option disabled>No results found. Type your own.</option>
            )}
          </select>
        </div>

        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
