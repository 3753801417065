import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import HolidayDialog from './HolidayDialog'; // Import the HolidayDialog component
import './HolidayRota.css';
import FloatingPopup from './FloatingPopup';
import { AuthContext } from './AuthContext';


const HolidayRota = () => {
  const { organisationId } = useContext(AuthContext); // Access organisationId

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [staffMembers, setStaffMembers] = useState([]);
  const [staffHolidays, setStaffHolidays] = useState([]);
  const [locums, setLocums] = useState([]);
  const [floatingData, setfloatingData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [popupContent, setPopupContent] = useState(null); // State for popup content
  //const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); // State for popup position
  const [dialogData, setDialogData] = useState(null); // State for dialog data
  const calendarRefs = useRef({}); // Track refs for calendar cells
  const [roleColorMap, setRoleColorMap] = useState({}); // State for role-color mapping

 
  const handleDialogClose = () => {
    // Perform any actions needed to refresh the UI
    fetchStaffHolidays(); // Refetch holidays or any other necessary data
    setDialogData(null); // Close the dialog
  };

  const handlePreviousYear = () => {
    setSelectedYear(prevYear => prevYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear(prevYear => prevYear + 1);
  };

  const handleYearChange = (e) => {
    setSelectedYear(Number(e.target.value));
  };

  const getTextColor = (backgroundColor) => {
    const color = backgroundColor.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? 'black' : 'white';
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleMouseEnter = (data) => {
    if (data.holidayEntries.length>0){
    setfloatingData(data);
    }
  };
  
  const handlefloatingClose = () => {
    setfloatingData(null);
  };
  

   
  const handleMouseLeave = () => {
   setfloatingData(null);
  };

  const handleCellClick = (dayData) => {
    // Open the dialog with the date of the cell
    setDialogData(dayData);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  };

  

  const yearOptions = [];
  for (let year = 2024; year <= currentYear + 5; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  
    const transformHolidayData = (data) => {
      return data.map(holiday => {
        const startDate = new Date(holiday.start);
        const endDate = new Date(holiday.end);
    
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
      //    hour: '2-digit',
       //   minute: '2-digit',
       //   second: '2-digit',
        //  hour12: false
        };
    
        return {
          ...holiday,
          start: startDate.toLocaleString('en-GB', options), // Convert to local date and time string in dd/mm/yyyy format
          end: endDate.toLocaleString('en-GB', options)    // Convert to local date and time string in dd/mm/yyyy format
        };
      });
    };
    
     
 //   const transformedEntries = transformHolidayData(holidayEntries);
  //  console.log(transformedEntries);
    

  const parseDateString = (dateString) => {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // JavaScript months are zero-based
  };
  

  const generateMonthData = (year, month) => {
    if (month==10){
      let xx=1;
    }
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay();

      const holidayEntries = staffHolidays.filter(holiday => {
        const holidayStart = new Date(holiday.start);
        const holidayEnd = new Date(holiday.end);
        return date >= holidayStart && date <= holidayEnd;
      });

      days.push({ day, dayOfWeek, holidayEntries, date });
    }
    return days;
  };

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const months = monthNames.map((monthName, index) => {
    const days = generateMonthData(selectedYear, index);
    return { monthName, days };
  });

  const fetchStaffMembers = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${encodeURIComponent(selectedLocation)}`;
      const staffResponse = await axios.get(staffUrl);
      setStaffMembers(staffResponse.data);
   //   //console.log('Successfully fetched staff members:', staffResponse.data);
    } catch (error) {
      //console.error('Failed to load staff members:', error);
    }
  };

  const fetchStaffHolidays = async () => {
    //console.log('Attempting to fetch staff holidays for year:', selectedYear);
  
    try {
   //   const roleParam = selectedRole ? `&role=${encodeURIComponent(selectedRole)}` : '';
      const locationParam = selectedLocation ? `&defaultLocationId=${encodeURIComponent(selectedLocation)}` : '';
      
     const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?year=${selectedYear}${locationParam}`;
  //const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday`;
      const response = await axios.get(holidaysUrl);
      //console.log('Successfully fetched staff holidays:', response.data);
  
      // Transform response data to handle UTC dates
      const transformedData = transformHolidayData(response.data);
  
      //console.log('Transformed Data:', transformedData);
      setStaffHolidays(response.data);
      let x=1;
    } catch (error) {
      //console.error(`Failed to load staff holidays for ${selectedYear}:`, error);
    }
  };
  const fetchLocations = async (orgId) => {
    try {
      const locationResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      const filteredLocations = locationResponse.data.filter(location => location.organisation === orgId);
      setLocations(filteredLocations); // Set t
  // Set selectedLocation to the _id of the first item in the locations array
      if (filteredLocations.length > 0) {
        setSelectedLocation(filteredLocations[0]._id);
      }

      // Set selectedYear to the current year
      if (selectedYear==''){
      const currentYear = new Date().getFullYear().toString();
      setSelectedYear(currentYear);
    }


    } catch (error) {
      //console.error('Failed to load locations:', error);
    }
  };

  const fetchStaffRoles = async () => {
    try {
      const rolesUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles';
      const response = await axios.get(rolesUrl);
      // console.log('Successfully fetched staff roles:', response.data);
  
      // Create a role-color map from the response
      const roleMap = response.data.reduce((map, role) => {
        // Trim leading and trailing spaces from role names and ensure they are strings
        const trimmedRole = role.role.trim();
        const color = role.colour; // Assuming the color field is named 'colour'
        if (trimmedRole && color) {
          map[trimmedRole] = color;
        }
        return map;
      }, {});
  
      // Set the roles for dropdown, trimming any extra spaces
      const trimmedRoles = response.data.map(role => role.role.trim());
      setStaffRoles(trimmedRoles);
  
      // Set the role-color map
      setRoleColorMap(roleMap);
    } catch (error) {
      // console.error('Failed to load staff roles:', error);
    }
  };
  
 
  useEffect(() => {
    // Fetch locations first
    const loadData = async () => {
      await fetchLocations(organisationId);
    };

    loadData();
  }, []);

  useEffect(() => {
    // Run other fetch routines when parameters change
    if (selectedYear && selectedLocation) {
      const fetchData = async () => {
        await fetchStaffMembers();
        await fetchStaffHolidays();
        await fetchStaffRoles();
      };

      fetchData();
    }
  }, [selectedYear, selectedLocation]);


  return (
    <div className="holiday-planner-container">
      <div className="planner-header">
        <h2 className="planner-title">Holiday Rota</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
  {Object.entries(roleColorMap).map(([role, color]) => (
    <div
      key={role}
      style={{
        backgroundColor: color,
        color: getTextColor(color),
        padding: '5px 10px',
        margin: '2px',
        fontSize: '10px',
        borderRadius: '4px',
        whiteSpace: 'nowrap'
      }}
    >
      {role}
    </div>
  ))}
</div>

        <div className="year-selector">
          <select value={selectedLocation} onChange={handleLocationChange}>
            {locations.map(location => (
              <option key={location.id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
          <select value={selectedYear} onChange={handleYearChange}>
            {yearOptions}
          </select>
          
          <button onClick={handlePreviousYear}>Previous Year</button>
          <button onClick={handleNextYear}>Next Year</button>
        </div>
      </div>
      <div className="calendar">
        {months.map((month, index) => (
          <div key={index} className="month-row">
            <div className="month-name">{month.monthName}</div>
            {month.days.map((dayData, dayIndex) => (
              <div
                key={dayIndex}
                ref={el => calendarRefs.current[`day-${dayIndex}`] = el} // Set ref
                className={`calendar-day ${dayData.dayOfWeek === 0 || dayData.dayOfWeek === 6 ? 'weekend' : ''}`}
                onMouseEnter={(event) => handleMouseEnter(dayData)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleCellClick(dayData)}
                tabIndex="0"
              >
                <span className="day-name">{dayNames[dayData.dayOfWeek]}</span>
                <span className="day-number">{dayData.day}</span>
                <div className="holiday-dots">
                  {dayData.holidayEntries.map((entry, index) => (
                    <div
                      key={index}
                      className="holiday-dot"
                      style={{ backgroundColor: roleColorMap[entry.role] || '#FF0000' }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
   
 {dialogData && staffMembers && (
  <HolidayDialog
    open={Boolean(dialogData && staffMembers)} // Ensures both dialogData and staffMembers are available
    onClose={handleDialogClose} // Use handleDialogClose to handle closing and refreshing
    data={dialogData} // Pass holiday data
    staffMembers={staffMembers} // Pass staff members data
  />
)}
{floatingData && (
  <FloatingPopup
    data={floatingData}
  //  position={popupPosition} // Positioning can be static if not used
    onClose={handlefloatingClose}
  />
)}
    </div>
  );
};

export default HolidayRota;
